<template>
  <section fluid>
    <v-row class="mt-2">
      <v-col cols="12" sm="3">
        <v-btn
          v-if="showActionAdd()"
          block
          @click="modalAddInsumo = true"
          class="secondary text-capitalize px-8"
        >
          Agregar
        </v-btn>
      </v-col>
    </v-row>

    <!-- table -->
    <DataTableComponent
      class="my-6"
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="cotizaciones_list"
      @paginar="paginate"
      :total_registros="totalRows"
    >
      <template #[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.monto)
        }}
      </template>
      <template #[`item.fecha_carga`]="{ item }">
        {{ moment(item.fecha_carga).format("DD/MM/YYYY") || "Sin fecha" }}
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip right v-if="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small fab>
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span> Ver </span>
        </v-tooltip>
        <v-tooltip right v-if="item.documento_ruta">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              fab
              @click="downloadFile(item.documento_ruta)"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span> Descargar </span>
        </v-tooltip>
        <v-tooltip right v-if="permitirEliminar">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              fab
              @click="deleteAction(item.id)"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </DataTableComponent>

    <!-- modal agregar insumo -->
    <v-dialog
      persistent
      max-width="1000"
      class="modal"
      v-model="modalAddInsumo"
    >
      <v-card max-width="1000" max-height="1050">
        <v-btn @click="modalAddInsumo = false" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text class="px-6 px-sm-10">
          <v-row no-gutters class="px-sm-10">
            <v-col cols="12" class="mb-3 text-center text-sm-start">
              <span class="text-h5 secondary--text"> Precios del mercado</span>
            </v-col>
            <v-col cols="12" class="my-1">
              <v-text-field
                v-model="infoArchivo.nombreArchivo"
                outlined
                label="Nombre del archivo*"
                @input="$v.infoArchivo.nombreArchivo.$touch()"
                @blur="$v.infoArchivo.nombreArchivo.$touch()"
                :error-messages="nombreArchivoError"
                clearable
                maxlength="75"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="my-1">
              <money-text-field-component
                ref="montoTxt"
                label="Monto*"
                outlined
                v-model="infoArchivo.precioUnitario"
                :error-messages="precioUnitarioError"
                @blur="$v.infoArchivo.precioUnitario.$touch()"
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="infoArchivo.archivo"
                outlined
                prepend-icon=""
                placeholder="Adjuntar archivo"
                label="Archivo*"
                @input="$v.infoArchivo.archivo.$touch()"
                @blur="$v.infoArchivo.archivo.$touch()"
                :error-messages="archivoError"
                accept="application/pdf"
                prepend-inner-icon="mdi-paperclip"
              />
            </v-col>
            <v-col cols="12" class="d-flex flex-column flex-sm-row">
              <v-btn
                color="secondary"
                class="text-capitalize mx-8 mb-2 mb-sm-0"
                outlined
                @click="clearInfoArchivo(), (modalAddInsumo = false)"
              >
                Cancelar
              </v-btn>
              <v-btn
                :loading="loadingAddBtn"
                :disabled="loadingAddBtn"
                color="secondary"
                class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0"
                @click="addInsumo()"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modal eliminar insumo -->
    <ConfirmationDialog
      :show="modalEliminarCotizacion"
      title="¿Deseas remover la cotización?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminarCotizacion = false"
      @confirm="deleteCotizacion()"
    />
  </section>
</template>
<script>
import { helpers, required } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import ConfirmationDialog from "../../../components/ConfirmationDialogComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";

import { mapMutations, mapState } from "vuex";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "PreciosMercadoComponent",
  components: {
    MoneyTextFieldComponent,
    ConfirmationDialog,
    DataTableComponent,
  },
  data: () => ({
    modalEliminarCotizacion: false,
    idCotizacion: null,
    modalAddInsumo: false,
    headers: [
      { text: "Documento", align: "center", value: "nombre_documento" },
      // {
      //   text: "Precio unitario ($)",
      //   align: "center",
      //   value: "monto",
      // },
      {
        text: "Precios del mercado ($)",
        align: "center",
        value: "monto",
      },
      { text: "Fecha de carga", align: "center", value: "fecha_carga" },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
    infoArchivo: {
      nombreArchivo: null,
      precioUnitario: null,
      archivo: null,
    },
    cotizaciones_list: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    loadingAddBtn: false,
  }),
  validations: {
    infoArchivo: {
      nombreArchivo: {
        required,
        txtField,
      },
      precioUnitario: {
        required,
      },
      archivo: {
        required,
        fileTypeValid,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
  },
  computed: {
    ...mapState("comprasModule", ["pasoSolicitud", "pasosSolicitudes"]),
    ...mapState("SolicitudCompraStore/ProcesoTradicionalStore", ["solicitud"]),
    ...mapState(["userInfo"]),
    ...mapState(['institucionesUsuario']),
    permitirEliminar() {
      return this.pasoSolicitud?.id == 1 || this.pasoSolicitud?.id == 8;
    },
    nombreArchivoError() {
      const errors = [];
      if (!this.$v.infoArchivo.nombreArchivo.$dirty) return errors;
      !this.$v.infoArchivo.nombreArchivo.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.infoArchivo.nombreArchivo.txtField &&
        errors.push(
          "El nombre del archivo no es válido, solo se permiten letras, números y guiones"
        );
      return errors;
    },
    precioUnitarioError() {
      const errors = [];
      if (!this.$v.infoArchivo.precioUnitario.$dirty) return errors;
      !this.$v.infoArchivo.precioUnitario.required &&
        errors.push("El monto es requerido");
      return errors;
    },
    archivoError() {
      const errors = [];
      if (!this.$v.infoArchivo.archivo.$dirty) return errors;
      !this.$v.infoArchivo.archivo.required &&
        errors.push("El archivo es requerido");
      !this.$v.infoArchivo.archivo.fileTypeValid &&
        errors.push("El archivo debe ser de tipo PDF");
      !this.$v.infoArchivo.archivo.fileNameValid &&
        errors.push("El nombre del archivo no es válido");
      !this.$v.infoArchivo.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
  },
  methods: {
    ...mapMutations("agregarInsumo", ["deletePrecioMercado"]),
    deleteAction(id) {
      this.modalEliminarCotizacion = true;
      this.idCotizacion = id;
    },
    clearInfoArchivo() {
      this.infoArchivo.nombreArchivo = null;
      this.infoArchivo.precioUnitario = null;
      this.infoArchivo.archivo = null;
      this.$refs.montoTxt.clear();
      this.$v.$reset();
    },
    async addInsumo() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        this.loadingAddBtn = true;
        let formData = new FormData();
        formData.append("nombre_archivo", this.infoArchivo.nombreArchivo);
        formData.append("monto", this.infoArchivo.precioUnitario);
        formData.append("documento", this.infoArchivo.archivo);

        const response =
          await this.services.SolicitudCompra.postSolicitudCotizaciones(
            this.$route.params.id_solicitud,
            formData
          ).catch(() => (this.loadingAddBtn = false));

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            message: "Precios de mercado agregado con éxito",
            type: "success",
          });
          this.clearInfoArchivo();
          this.getCotizaciones();
          this.modalAddInsumo = false;
        }
        this.loadingAddBtn = false;
      }
    },
    async deleteCotizacion() {
      const { status } =
        await this.services.SolicitudCompra.deleteSolicitudCotizacionDetalle(
          this.idCotizacion
        );

      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "Detalle de cotización eliminado con éxito",
          type: "success",
        });
        this.getCotizaciones();
        this.modalEliminarCotizacion = false;
      }
    },
    async downloadFile(route) {

      try {
        const response = await this.services.SolicitudCompra.getUrlDocumento({
          documento: route,
        });
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();
        }
      } catch (error) {

        console.log(error);
      }

    },
    async getCotizaciones() {

      const { status, data, headers } =
        await this.services.SolicitudCompra.getSolicitudCotizaciones(
          this.$route.params.id_solicitud,
          {
            page: this.page,
            per_page: this.perPage,
          }
        );
      if (status == 200) {
        this.cotizaciones_list = data;
        this.totalRows = Number(headers.total_rows);
      }

    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getCotizaciones();
    },
    showActionAdd() {
      if (this.solicitud && this.solicitud?.paso?.id == 2) {
        if (this.haveRole("ROLE_JEFE_UNIDAD")) {
          // const idsInstitucionesUsuario = this.institucionesUsuario.map((i) => i.id);
          // const institucionProcesoCompraPac = this.solicitud.proceso_compra_pac.id_institucion;
          // return idsInstitucionesUsuario.includes(institucionProcesoCompraPac);

          //Fixeando para quitar validacion y dejar middleware de validarEspecificaProcesoCompra
          //Validacion para solo dejar pasar el boton sin afectar lo ya existente
          const idsInstitucionesUsuario = this.institucionesUsuario.map((i) => i.id);
          return idsInstitucionesUsuario;
        }
      }

      return (this.haveRole("ROLE_TECNICO_UNIDAD") ||
        this.haveRole("ROLE_JEFE_UNIDAD")) &&
        (this.solicitud?.paso == null ||
          this.solicitud?.paso?.id == 1 ||
          this.solicitud?.paso?.id == 8)
        ? true
        : false;
    },
  },
  created() {
    this.getCotizaciones();
  },
};
</script>
